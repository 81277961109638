<template>
  <main>
    <page-header-compact>
      Dashboard
    </page-header-compact>
    <div class="container-fluid px-4">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-sm-12 col-sm-12 mt-2 ">
          <div class="card">
            <div class="card-body text-center">
              <img src="@/assets/img/loginbg.png" width="590px">
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-sm-12 mt-2 ">
          <div class="card">
            <div class="card-body">
              <div class="text-center mb-2 mt-2">
                <img src="@/assets/img/usuario.png" width="96px" height="auto" >
              </div>
              <div class="text-center mb-4 ">
                <h4 class="text-primary">{{user.name}}</h4>
                <i class="text-muted">{{user.email}}</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PageHeaderCompact from "../../components/layouts/content/page-header-compact";
export default {
  name: "dashboard.vue",
  components: {PageHeaderCompact},
  computed: {
    user() {
      return this.$store.state.user;
    }
  }
}
</script>

<style scoped>

</style>
